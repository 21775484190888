import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import times from 'lodash/times';
import uniqBy from 'lodash/uniqBy';
import React, {useEffect, useState} from 'react';
import WhiteText from '../../components/WhiteText';
import filterBoolean from '../../functions/filterBoolean';
import useStatus from '../../hooks/useStatus';
import Funnel from '../../models/fountain/Funnel';
import LocationGroup from '../../models/fountain/LocationGroup';
import ScoopMApi from '../../references/scoopm-api';

type GroupWithFunnels = LocationGroup & {funnels?: Funnel[]};

export default function Openings() {
  // const {handlePromise, status, error} = useStatus();
  // const [groups, setGroups] = useState<GroupWithFunnels[]>();

  // useEffect(() => {
  //   handlePromise(
  //     getFunnels().then(funnels => {
  //       let locationGroups = filterBoolean(funnels.map(funnel => funnel.location?.location_group)).filter(
  //         group => group.name !== 'Unassigned',
  //       );
  //       locationGroups = uniqBy(locationGroups, 'id');
  //       locationGroups = sortBy(locationGroups, 'name');
  //       let funnelsToShow: Funnel[] = (filter as any)(funnels, 'active', ['is_private', false]);
  //       funnelsToShow = sortBy(funnelsToShow, 'title');
  //       funnelsToShow.forEach(funnel => {
  //         const group: GroupWithFunnels | undefined = locationGroups.find(
  //           group => group.id === funnel.location?.location_group?.id,
  //         );
  //         if (group) {
  //           if (!group.funnels) {
  //             group.funnels = [];
  //           }
  //           group.funnels.push(funnel);
  //         }
  //       });
  //       setGroups(locationGroups);
  //     }),
  //   );
  // }, [handlePromise]);

  // render

  return (
    <div className="container py-2 py-md-3">
      <h1 className="display-2 mb-0">Drive for Wave</h1>
      {/* <p className="lead mb-4">Check out openings in a city near you.</p> */}
      <br />

      <h2>Rideshare and Delivery Driver Applicant Portal</h2>
      <a target="_blank" style={{color: '#00B6D9'}} href="https://wave-driver-application.zapier.app/">Click here to apply!</a>
      <br/>
      <br/>
      {/* <h2>Rideshare Driver Application</h2>
      <a target="_blank"  style={{color: '#00B6D9'}} href="https://onboarding.gigsafe.com/onboarding/waveride/sign-up?partnerMarketId=b79b30d3-fe6a-4e6d-9bee-a20eacc678f2&onboardingTemplateId=efe4c740-1bc3-4325-b063-c20d348e4ddf">Click here to apply!</a> */}
    {/*  {error ? (
        <div className="alert alert-warning">Unable to list openings at this time</div>
      ) : groups ? (
        groups.map(group => (
          <div key={group.id} className="mb-3">
            <h1 className="display-4 text-primary mb-2">{group.name}</h1>
            {group.funnels?.map(funnel => (
              <div key={funnel.id} className="mb-1">
                <a href={funnel.apply_url} target="_blank" rel="noopener noreferrer">
                  {funnel.title}
                </a>
              </div>
            )) ?? <span className="text-muted">No openings at this time</span>}
          </div>
        ))
      ) : (
        times(2, i => (
          <div key={i} style={{width: '350px'}} className="mb-3">
            <div className="loading-animation">
              <h1 className="display-4 mb-2">
                <WhiteText />
              </h1>
            </div>
            {times(12, j => (
              <div key={j} className="loading-animation mb-1">
                <WhiteText />
              </div>
            ))}
          </div>
        ))
      )}

      {groups && (
        <>
          <hr />
          <div className="mt-4 mb-5">
            <small className="text-muted">
              You'll fill out your application on the secure site of our our partner,{' '}
              <a href="https://get.fountain.com/" target="_blank" rel="noopener noreferrer">
                Fountain
              </a>
            </small>
          </div>
        </>
      )}
      */}
    </div>
  );
}

// async function getFunnels(page = 0): Promise<Funnel[]> {
//   const response = await ScoopMApi.instance.fountain.funnels.list(page);
//   if (response.data.pagination.current < response.data.pagination.last) {
//     return [...response.data.funnels, ...(await getFunnels(page + 1))];
//   }
//   return response.data.funnels;
// }
